import { ZING_CONTACT_SUCCESS, ZING_CONTACT_PENDING, ZING_CONTACT_ERROR } from "../actions/zingContact";

const initState = {
    validationState: "Unknown", // Valid, Invalid, Unknown, Pending
    contactKey: "",
    zingUserUuid: "",
    displayName: "",
    lastUpdated: 0
};


const zingContact = (state = initState, action) => {
    switch (action.type) {
        case ZING_CONTACT_PENDING:
            return Object.assign({}, state, {
                validationState: "Pending",
                contactKey: action.contactKey,
                zingUserUuid: "",
                displayName: "",
                lastUpdated: 0,
            });
        case ZING_CONTACT_SUCCESS:
            return Object.assign({}, state, {
                validationState: "Valid",
                zingUserUuid: action.zingUserUuid,
                displayName: action.displayName,
                lastUpdated: action.lastUpdated
            });
        case ZING_CONTACT_ERROR:
            return Object.assign({}, state, {
                validationState: "Invalid",
                zingUserUuid: ""
            });

        // case ZING_CONTACT_INVALIDATE:
        //     return Object.assign({}, state, {
        //         contactKey: action.newValue,
        //         validationState: "Unknown",
        //     });

        default:
            return state;
    }
}

export default zingContact;