import React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
//import "./App.css";
import Container from "react-bootstrap/Container";
import AnchorLink from 'react-anchor-link-smooth-scroll';


 export default () => 
      <div className="HomeNav">
        {/*-- Navigation */}
        <Navbar expand="lg" fixed="top" variant="light" id="mainNav" className="navbar-shrink">
          <Container>
            <AnchorLink href="#page-top">
              <Navbar.Brand className="js-scroll-trigger">Zing Diary</Navbar.Brand>
            </AnchorLink>
            <Navbar.Toggle className="navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
              Menu
              <i className="fas fa-bars"></i>
            </Navbar.Toggle>
            <Navbar.Collapse className="collapse" id="navbarResponsive">
              <Nav className="ml-auto">
                <Nav.Item>
                  <AnchorLink className="nav-link" href="#download">Download</AnchorLink>
                </Nav.Item>
                <Nav.Item>
                  <AnchorLink className="nav-link" href="#features">Features</AnchorLink>
                </Nav.Item>
                <Nav.Item>
                  <AnchorLink className="nav-link" href="#contact">Contact</AnchorLink>
                </Nav.Item>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        {/* <Navbar bg="light">
          <Navbar.Brand>
            <Link to="/">Zing Diary</Link>
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse>
            <Nav className="ml-auto">
              {this.state.isAuthenticated
                ? <Nav.Item onClick={this.handleLogout}>Logout</Nav.Item>
                : <Fragment>
                  <LinkContainer to="/login">
                    <Nav.Link>Login</Nav.Link>
                  </LinkContainer>
                </Fragment>
              }

            </Nav>
          </Navbar.Collapse>

        </Navbar> */}
       </div>;
