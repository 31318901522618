import React, { Component } from "react";
import { connect } from "react-redux";
import "./Admin.css";
import ReactPhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/dist/style.css'

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import AlertOnCondition from "../../components/AlertOnCondition";

import { onUserPhoneChange, adminSetUser } from "../../actions/userSession";


class Admin extends Component {
  handleSubmit = async event => {
    event.preventDefault();
    this.props.adminSetUser(this.props.userPhone);
  }

  handleOnChange = async value => {
    this.props.onUserPhoneChange(value);
  }

  render() {
    if (this.props.signInState !== "SignedIn") {
      return (
        <div className="AdminLanding">
          <h3>Please login to continue</h3>
        </div>);
    }
    return (
      <div className="Admin">
        <Form onSubmit={this.handleSubmit}>
          <Form.Group controlId="phone" size="lg">
            <Form.Label>Phone Number</Form.Label>
            <ReactPhoneInput defaultCountry={"gb"} onChange={this.handleOnChange} value={this.props.userPhone} />
            <Form.Text className="text-danger" hidden={!(this.props.validationState === "Invalid")}>
              Sorry, that phone number was not recognised.
            </Form.Text>
          </Form.Group>
          <Button variant="primary" type="submit">
            Submit
          </Button>
          <Form.Text>State: {this.props.validationState}</Form.Text>
        </Form>
        <AlertOnCondition alertOnEntry={false} show={this.props.validationState === "Invalid"} title="Error" message="Cannot find this id." />
        <hr className="fluid" />
        <Container fluid>
          <Row>
            <Col sm className="h3 bg-light">ZingContact</Col>
          </Row>
          <Row>
            <Col sm={6}><Row><Col sm={4} className="text-right text-muted">Contact Key</Col><Col sm={8}>{this.props.contactKey}</Col></Row></Col>
            <Col sm={6}><Row><Col sm={4} className="text-right text-muted">Display Name</Col><Col sm={8}>{this.props.contactDisplayName}</Col></Row></Col>
            <Col sm={6}><Row><Col sm={4} className="text-right text-muted">Zing User Uuid</Col><Col sm={8}>{this.props.contactZingUserUuid}</Col></Row></Col>
            <Col sm={6}><Row><Col sm={4} className="text-right text-muted">Last Updated</Col><Col sm={8}>{this.props.contactLastUpdated} <i className="small" >{new Date(this.props.contactLastUpdated).toUTCString()}</i></Col></Row></Col>
          </Row>
          <Row>
            <Col sm className="h3 bg-light">ZingUser</Col>
          </Row>
          <Row>
            <Col sm={6}><Row><Col sm={4} className="text-right text-muted">Zing User Uuid</Col><Col sm={8}>{this.props.userZingUserUuid}</Col></Row></Col>
            <Col sm={6}><Row><Col sm={4} className="text-right text-muted">Display Name</Col><Col sm={8}>{this.props.userDisplayName}</Col></Row></Col>
            <Col sm={6}><Row><Col sm={4} className="text-right text-muted">Membership Status</Col><Col sm={8}>{this.props.userMembershipStatus}</Col></Row></Col>
            <Col sm={6}><Row><Col sm={4} className="text-right text-muted">Last Updated</Col><Col sm={8}>{this.props.userLastUpdated} <i className="small" >{new Date(this.props.userLastUpdated).toUTCString()}</i></Col></Row></Col>
            <Col sm={12}><Row><Col sm={2} className="text-right text-muted">Payload Json</Col><Col sm={10}>{this.props.userPayloadJson}</Col></Row></Col>
            <Col sm={6}><Row><Col sm={4} className="text-right text-muted">Default Calendar Uuid</Col><Col sm={8}>{this.props.userPayloadJson==="" ? "" : JSON.parse(this.props.userPayloadJson).defaultCalendarUuid}</Col></Row></Col>

          </Row>
          <Row>
            <Col sm className="h3 bg-light">ZingCalendar</Col>
          </Row>
          <Row>
            <Col sm={6}><Row><Col sm={4} className="text-right text-muted">Zing Calendar Uuid</Col><Col sm={8}>{this.props.calendarCalendarUuid}</Col></Row></Col>
            <Col sm={6}><Row><Col sm={4} className="text-right text-muted">Display Name</Col><Col sm={8}>{this.props.calendarDisplayName}</Col></Row></Col>
            <Col sm={6}><Row><Col sm={4} className="text-right text-muted">Visibility</Col><Col sm={8}>{this.props.calendarVisibility}</Col></Row></Col>
            <Col sm={6}><Row><Col sm={4} className="text-right text-muted">Last Updated</Col><Col sm={8}>{this.props.calendarLastUpdated} <i className="small" >{new Date(this.props.userLastUpdated).toUTCString()}</i></Col></Row></Col>
            <Col sm={12}><Row><Col sm={2} className="text-right text-muted">Created by User Uuid</Col><Col sm={10}>{this.props.calendarCreatedByUserUuid}</Col></Row></Col>

          </Row>
          <Row>
            <Col sm className="h3 bg-light">FriendRequests</Col>
          </Row>
          <Row>
            <Col sm={6}><Row><Col sm={4} className="text-right text-muted">State</Col><Col sm={8}>{this.props.friendRequestBySenderValidationStatus}</Col></Row></Col>
          </Row>
        </Container>
      </div>);
  }
};


// ----------- Redux ------------
const mapStateToProps = (state) => {
  return {
    signInState: state.administratorSession.adminSignInState,
    userPhone: state.userSession.userPhone,
    validationState: state.zingContact.validationState,
    contactZingUserUuid: state.zingContact.zingUserUuid,
    contactDisplayName: state.zingContact.displayName,
    contactLastUpdated: state.zingContact.lastUpdated,
    contactKey: state.zingContact.contactKey,
    userZingUserUuid: state.zingUser.zingUserUuid,
    userDisplayName: state.zingUser.displayName,
    userLastUpdated: state.zingUser.lastUpdated,
    userPayloadJson: state.zingUser.payloadJson,
    userMembershipStatus: state.zingUser.membershipStatus,
    calendarCalendarUuid: state.zingCalendar.zingCalendarUuid,
    calendarDisplayName: state.zingCalendar.displayName,
    calendarLastUpdated: state.zingCalendar.lastUpdated,
    calendarVisibility: state.zingCalendar.visibility,
    calendarCreatedByUserUuid: state.zingCalendar.createdByUserUuid,
    friendRequestBySenderValidationStatus: state.friendRequest.validationState,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onUserPhoneChange: (newValue) => { dispatch(onUserPhoneChange(newValue)) },
    adminSetUser: (phone) => { dispatch(adminSetUser(phone)) },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Admin);
