const initState = {
    adminSignInState: "SignedOut",
    adminPhone: "",
    adminPassword: "",
};


const userSession = (state = initState, action) => {
    switch (action.type) {
        case "ADMIN_SIGN_IN_PENDING":
            return Object.assign({}, state, { adminSignInState: "Pending" });
        case "ADMIN_SIGN_IN_SUCCESS":
            return Object.assign({}, state, { adminSignInState: "SignedIn" });
        case "ADMIN_SIGN_IN_ERROR":
        case "ADMIN_SIGN_OUT_SUCCESS":
            return Object.assign({}, state, { adminSignInState: "SignedOut" });
        case "ADMIN_PHONE_CHANGED":
            return Object.assign({}, state, { adminPhone: action.newValue });
        case "ADMIN_PASSWORD_CHANGED":
            return Object.assign({}, state, { adminPassword: action.newValue });

        default:
            return state;
    }
}

export default userSession
