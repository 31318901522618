/*
 * Friend Reqeust has following
 * Each Friend Request json has following attributes:
 *
 * - validationState
 * - friendRequestUuid
 * - senderUserUuid
 * - recipientUserUuid
 * - (there are more, but that's a good starter)
 */

 const initState = {
     validationState: "Invalid",
     friendRequests: [],
 }

const friendRequest = (state = initState, action) => {
    switch (action.type) {
        case "FRIEND_REQUEST_BY_SENDER_PENDING":
            return Object.assign({}, state, {
                validationState: "Pending",
            });
        case "FRIEND_REQUEST_BY_SENDER_SUCCESS":
            return Object.assign({}, state, {
                validationState: "Valid",
                friendRequests: [
                    ...state.friendRequests,
                    {
                        senderUserUuid: action.senderUserUuid,
                        recipientUserUuid: action.recipientUserUuid,
                    }
                ]
            });
        case "FRIEND_REQUEST_BY_SENDER_ERROR":
            return Object.assign({}, state, {
                validationState: "Invalid",
                friendRequests:[]
            });

        // case "FRIEND_REQUEST_INVALIDATE":
        //     return Object.assign({}, state, {
        //         validationState: "Unknown",
        //         friendRequests: []
        //     });

        default:
            return state;
    }
}

export default friendRequest;