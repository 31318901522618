import React, { Component } from "react";
import { PropTypes } from "prop-types";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

class AlertOnCondition extends Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        message: PropTypes.string.isRequired,
    }

    constructor(props) {
        super(props);

        this.state = {
            prevValue: this.props.alertOnEntry ? false : true,
            show: true,
        };
    }

    handleClose = () => this.setState(Object.assign({}, this.state, {show: false}));
    //handleShow = () => setState({show: true});

  
    render() {
        // Only do something if the underlying value has changed
        const currValue = this.props.show;
        const prevValue = this.state.prevValue;
        this.state.prevValue = currValue;

        if (currValue && !prevValue) {
            // State has changed from false to true, we
            // should show the dialog if it's not already active
            if(!this.state.show) {
                this.setState({show: true});
                // ... and return as we're about to be re-rendered
                // we don't want an infinite render loop do we
                return "";
            }
        }

        if (!currValue && prevValue) {
            // State has changed from true to false, we
            // should hide the dialog if it's not already hidden
            if(this.state.show) {
                this.setState({show: false});
                // ... and return as we're about to be re-rendered
                // we don't want an infinite render loop do we
                return "";
            }
        }

       return (
      <Modal show={this.state.show} onHide={this.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{this.props.message}</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={this.handleClose}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
      );
    }
}

export default AlertOnCondition;