import { USER_PHONE_CHANGED } from "../actions/userSession";

const initState = {
    userPhone: "",
};


const userSession = (state = initState, action) => {
    switch (action.type) {
        case USER_PHONE_CHANGED:
            return Object.assign({}, state, { userPhone: action.newValue });

        default:
            return state;
    }
}

export default userSession;