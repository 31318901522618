import React from "react";
import { Route, Switch } from "react-router-dom";
import AdminNav from "./containers/nav/AdminNav";
import ErrorNav from "./containers/nav/ErrorNav";
import HomeNav from "./containers/nav/HomeNav";

export default () =>
  <Switch>
    <Route path="/" exact component={HomeNav}/>
    <Route path="/admin" exact component={AdminNav} />
    <Route path="/login" exact component={AdminNav} />
    { /* Finally, catch all unmatched routes */ }
    <Route component={ErrorNav} />
  </Switch>;
