import { ZING_USER_SUCCESS, ZING_USER_PENDING, ZING_USER_ERROR } from "../actions/zingUser";

const initState = {
    validationState: "Unknown", // Valid, Invalid, Unknown, Pending
    zingUserUuid: "",
    displayName: "",
    membershipStatus: "",
    payloadJson: "",
    lastUpdated: 0,
};


const zingUser= (state = initState, action) => {
    switch (action.type) {
        case ZING_USER_PENDING:
            return Object.assign({}, state, {
                validationState: "Pending",
                zingUserUuid: action.zingUserUuid,
                displayName: "",
                membershipStatus: "",
                payloadJson: "",
                lastUpdated: 0,
            });
        case ZING_USER_SUCCESS:
            return Object.assign({}, state, {
                validationState: "Valid",
                displayName: action.displayName,
                membershipStatus: action.membershipStatus,
                payloadJson: action.payloadJson,
                lastUpdated: action.lastUpdated,
                        });
        case ZING_USER_ERROR:
            return Object.assign({}, state, {
                validationState: "Invalid",
                displayName: "",
                membershipStatus: "",
                payloadJson: "",
                lastUpdated: 0,
            });
        // case "ZING_USER_INVALIDATE":
        //     return Object.assign({}, state, {
        //         validationState: "Unknown",
        //         zingUserUuid: "",
        //         displayName: "",
        //         membershipStatus: "",
        //         payloadJson: "",
        //         lastUpdated: 0,
        //     });

        default:
            return state;
    }
}

export default zingUser;