import { API } from "aws-amplify";

export const ZING_CALENDAR_SUCCESS = "ZING_CALENDAR_SUCCESS";
export const ZING_CALENDAR_PENDING = "ZING_CALENDAR_PENDING";
export const ZING_CALENDAR_ERROR = "ZING_CALENDAR_ERROR";

export function zingCalendarSuccess(zingCalendar) {
    return {
        type: ZING_CALENDAR_SUCCESS,
        zingCalendarUuid: zingCalendar.zingCalendarUuid,
        displayName: zingCalendar.displayName,
        visibility: zingCalendar.visibility,
        createdByUserUuid: zingCalendar.createdByUserUuid,
        lastUpdated: zingCalendar.lastUpdated,
    }
}

export function zingCalendarPending(zingCalendarUuid) {
    return {
        type: ZING_CALENDAR_PENDING,
        zingCalendarUuid
    }
}

export function zingCalendarError(errorMessage) {
    return {
        type: ZING_CALENDAR_ERROR,
        errorMessage
    }
}

export function getZingCalendar(zingCalendarUuid) {
    return (dispatch, getState) => {
        dispatch(zingCalendarPending(zingCalendarUuid));

        API.get("user", `/zing-calendar/${zingCalendarUuid}`)
            .then((response) => {
                dispatch(zingCalendarSuccess(response));
            })
            .catch((e) => {
                dispatch(zingCalendarError(e.message));
            });
    }
}

