export default {
    apiGateway: {
      REGION: "eu-west-1",
      USER_SERVICE_URL: "https://sqtbywe1fc.execute-api.eu-west-1.amazonaws.com/dev",
      MESSAGE_SERVICE_URL: "https://vvc9gghufj.execute-api.eu-west-1.amazonaws.com/dev",
    },
    cognito: {
      REGION: "eu-west-1",
      USER_POOL_ID: "eu-west-1_JUgvUBAPP",
      APP_CLIENT_ID: "4e6o2m0njtmf7ar2rrtans6q3a",
      IDENTITY_POOL_ID: "eu-west-1:f8b8a130-c494-4695-96eb-15b17a0c74fe"
    }
};