import React from "react";
import {LinkContainer} from "react-router-bootstrap";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";


export default () =>
  <div className="ErrorNav">
    {/*-- Navigation */}
    <Navbar expand="lg" fixed="top" variant="light" id="mainNav" className="navbar-shrink">
      <Container>
        <LinkContainer to="/">
          <Navbar.Brand>Zing Diary</Navbar.Brand>
        </LinkContainer>
      </Container>
    </Navbar>

    {/* <Navbar bg="light">
          <Navbar.Brand>
            <Link to="/">Zing Diary</Link>
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse>
            <Nav className="ml-auto">
              {this.state.isAuthenticated
                ? <Nav.Item onClick={this.handleLogout}>Logout</Nav.Item>
                : <Fragment>
                  <LinkContainer to="/login">
                    <Nav.Link>Login</Nav.Link>
                  </LinkContainer>
                </Fragment>
              }

            </Nav>
          </Navbar.Collapse>

        </Navbar> */}
  </div>;
