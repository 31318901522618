import React, { Component } from "react";
import "./Home.css";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Row from "react-bootstrap/Row";
import AnchorLink from 'react-anchor-link-smooth-scroll'
import "../scss/zing-diary.scss";


export default class Home extends Component {
  render() {
    return (
      <div className="Home">
        <div className="lander">
          <header className="masthead">
            <Container className="h-100">
              <Row className="h-100">
                <Col lg={7} className="my-auto">
                  <div className="header-content mx-auto">
                    <h1 className="mb-5">Zing Diary</h1>
                    <h2 className="mb-5">A social calendar for a connected world.</h2>
                    <AnchorLink href="#download"><Button variant="outline-light" size="xl" className="js-scroll-trigger">Start Now for Free!</Button></AnchorLink>
                  </div>
                </Col>
                <Col lg={5} className="my-auto">
                  <div className="device-container">
                    <div className="device-mockup iphone6_plus portrait white">
                      <div className="device">
                        <div className="screen">
                          {/* Demo image for screen mockup, you can put an image here, some HTML, an animation, video, or anything else! */}
                          <Image src="img/demo-screen-1.png" fluid alt="" />
                        </div>
                        <div className="button">
                          {/* You can hook the "home button" to some JavaScript events or just remove it */}
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </header>

          <section className="download bg-primary text-center" id="download">
            <Container>
              <Row>
                <Col md={8} className="mx-auto">
                  <h2 className="section-heading">Coming soon!</h2>
                  <p>Our app will be available on any mobile device!</p>
                  <OverlayTrigger trigger="click" fluid
                      overlay={<Popover id="popover-basic">
                      <Popover.Title as="h3">Come back soon</Popover.Title>
                      <Popover.Content>
                        We're not quite ready. Please try again soon.
                      </Popover.Content>
                    </Popover>}>                 
                    <div className="badges">
                    <a className="badge-link" href="."><Image src="img/google-play-badge.svg" alt="" /></a>
                    <a className="badge-link" href="."><Image src="img/app-store-badge.svg" alt="" fluid/></a>
                  </div>
                  </OverlayTrigger>
                </Col>
              </Row>
            </Container>
          </section>

          <section className="features" id="features">
            <Container>
              <div className="section-heading text-center">
                <h2>Simple shared calendar</h2>
                <p className="text-muted">Easy setup. Sync with your work calendar. Share appointments with family. Invite friends to events.</p>
                <hr />
              </div>
              <Row>
                <Col lg={4} className="my-auto">
                  <div className="device-container">
                    <div className="device-mockup iphone6_plus portrait white">
                      <div className="device">
                        <div className="screen">
                          {/*  Demo image for screen mockup, you can put an image here, some HTML, an animation, video, or anything else! */}
                          <Image src="img/demo-screen-1.png" fluid alt="" />
                        </div>
                        <div className="button">
                          {/*  You can hook the "home button" to some JavaScript events or just remove it */}
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={8} className="my-auto">
                  <Container fluid>
                    <Row>
                      <Col lg={6}>
                        <div className="feature-item">
                          <i className="icon-people text-primary"></i>
                          <h3>Shared Diary</h3>
                          <p className="text-muted">Share your availability with family and friends</p>
                        </div>
                      </Col>
                      <Col lg={6} >
                        <div className="feature-item">
                          <i className="icon-refresh text-primary"></i>
                          <h3>Sync Calendar</h3>
                          <p className="text-muted">Sync with your existing calendars. Outlook, Google, etc.</p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <div className="col-lg-6">
                        <div className="feature-item">
                          <i className="icon-layers text-primary"></i>
                          <h3>Voting</h3>
                          <p className="text-muted">Allow invitees to let you know when is best for them</p>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="feature-item">
                          <i className="icon-lock text-primary"></i>
                          <h3>Private</h3>
                          <p className="text-muted">Only share details you want to. Friends won't see exactly what you're up to.</p>
                        </div>
                      </div>
                    </Row>
                  </Container>
                </Col>
              </Row>
            </Container>
          </section>

          <section className="cta">
            <div className="cta-content">
              <Container>
                <h2>Schedule it.<br />Zing it.<br />Done!</h2>
                <a href="#contact" className="btn btn-outline btn-xl js-scroll-trigger">Tell your friends!</a>
              </Container>
            </div>
            <div className="overlay"></div>
          </section>

          <section className="contact bg-primary" id="contact">
            <Container>
              <h2>We
        <i className="fas fa-heart"></i>
                new friends!</h2>
              <ul className="list-inline list-social">
                <li className="list-inline-item social-twitter">
                  <a href=".">
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>
                <li className="list-inline-item social-facebook">
                  <a href=".">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li className="list-inline-item social-google-plus">
                  <a href=".">
                    <i className="fab fa-google-plus-g"></i>
                  </a>
                </li>
              </ul>
            </Container>
          </section>

          <footer>
            <Container>
              <p>&copy; Zing Diary 2019. All Rights Reserved.</p>
              <ul className="list-inline">
                <li className="list-inline-item">
                  <a href=".">Privacy</a>
                </li>
                <li className="list-inline-item">
                  <a href=".">Terms</a>
                </li>
                <li className="list-inline-item">
                <a href=".">FAQ</a>
                </li>
              </ul>
            </Container>
          </footer>
        </div>
      </div>
    );
  }
}
