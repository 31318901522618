
import { getZingContact } from "./zingContact";

export const USER_PHONE_CHANGED = "USER_PHONE_CHANGED";

export function onUserPhoneChange(newValue){
    return {
        type: USER_PHONE_CHANGED,
        newValue
    }
}

export function adminSetUser(phone) {
    return (dispatch, getState) => {
        const contactKey = "P:" + phone.replace(/[ ()-]/g, "");
        return dispatch(getZingContact(contactKey));
    }
}

