import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from "react-router-dom";
import Amplify from "aws-amplify";
import awsconfig from "./awsconfig"

import rootReducer from "./reducers/index";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk"; 

Amplify.configure({
    Auth: {
      mandatorySignIn: true,
      region: awsconfig.cognito.REGION,
      userPoolId: awsconfig.cognito.USER_POOL_ID,
      identityPoolId: awsconfig.cognito.IDENTITY_POOL_ID,
      userPoolWebClientId: awsconfig.cognito.APP_CLIENT_ID
    },
    API: {
      endpoints: [
        {
          name: "user",
          endpoint: awsconfig.apiGateway.USER_SERVICE_URL,
          region: awsconfig.apiGateway.REGION
        },
        {
          name: "message",
          endpoint: awsconfig.apiGateway.MESSAGE_SERVICE_URL,
          region: awsconfig.apiGateway.REGION
        },
      ]
    }
  });

// Redux store
const store = createStore(
    rootReducer,
    applyMiddleware(thunk)
)

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App store={store}/>
    </Router>
    </Provider>,
    document.getElementById("root")
  );
  
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
