import { API } from "aws-amplify";
import { getZingCalendar } from "./zingCalendar";
import { getFriendRequestsForUser } from "./friendRequest";

export const ZING_USER_SUCCESS = "ZING_USER_SUCCESS";
export const ZING_USER_PENDING = "ZING_USER_PENDING";
export const ZING_USER_ERROR = "ZING_USER_ERROR";

export function zingUserSuccess(zingUser) {
    return {
        type: ZING_USER_SUCCESS,
        zingUserUuid: zingUser.zingUserUuid,
        displayName: zingUser.displayName,
        membershipStatus: zingUser.membershipStatus,
        payloadJson: zingUser.payloadJson,
        lastUpdated: zingUser.lastUpdated,
    }
}

export function zingUserPending(zingUserUuid) {
    return {
        type: ZING_USER_PENDING,
        zingUserUuid
    }
}

export function zingUserError(errorMessage) {
    return {
        type: ZING_USER_ERROR,
        errorMessage
    }
}

export function getZingUser(zingUserUuid) {
    return (dispatch, getState) => {
        dispatch(zingUserPending(zingUserUuid));

        API.get("user", `/zing-user/${zingUserUuid}`)
            .then((response) => {
                try {
                    const payload = JSON.parse(response.payloadJson);
                    dispatch(getZingCalendar(payload.defaultCalendarUuid));
                    dispatch(getFriendRequestsForUser(response.zingUserUuid));
                } catch {
                    // do nothing
                }
                return dispatch(zingUserSuccess(response));
            })
            .catch((e) => {
                dispatch(zingUserError(e.message));
            });

    }

}

