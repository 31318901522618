import { ZING_CALENDAR_SUCCESS, ZING_CALENDAR_PENDING, ZING_CALENDAR_ERROR } from "../actions/zingCalendar";

const initState = {
    validationState: "Unknown", // Valid, Invalid, Unknown, Pending
    zingCalendarUuid: "",
    displayName: "",
    visibility: "",
    createdByUserUuid: "",
    lastUpdated: 0,
};


const zingCalendar = (state = initState, action) => {
    switch (action.type) {
        case ZING_CALENDAR_PENDING:
            return Object.assign({}, state, {
                validationState: "Pending",
                zingCalendarUuid: action.zingCalendarUuid,
                displayName: "",
                visibility: "",
                createdByUserUuid: "",
                lastUpdated: 0,
            });
        case ZING_CALENDAR_SUCCESS:
            return Object.assign({}, state, {
                validationState: "Valid",
                displayName: action.displayName,
                visibility: action.visibility,
                createdByUserUuid: action.createdByUserUuid,
                lastUpdated: action.lastUpdated,
                        });
        case ZING_CALENDAR_ERROR:
            return Object.assign({}, state, {
                validationState: "Invalid",
                displayName: "",
                visibility: "",
                createdByUserUuid: "",
                lastUpdated: 0,
            });

        // case ZING_CALENDAR_INVALIDATE:
        //     return Object.assign({}, state, {
        //         validationState: "Unknown",
        //         zingCalendarUuid: "",
        //         displayName: "",
        //         visibility: "",
        //         createdByUserUuid: "",
        //         lastUpdated: 0,
        //     });

        default:
            return state;
    }
}

export default zingCalendar;