import { Auth } from "aws-amplify";

export function adminSignedIn() {
    return {
        type: "ADMIN_SIGN_IN_SUCCESS",
    }
}

export function adminSignedOut() {
    return {
        type: "ADMIN_SIGN_OUT_SUCCESS",
    }
}

export function adminSignInPending() {
    return {
        type: "ADMIN_SIGN_IN_PENDING",
    }
}

export function onAdminPhoneChange(newValue){
    return {
        type: "ADMIN_PHONE_CHANGED",
        newValue
    }
}

export function onAdminPasswordChange(newValue){
    return {
        type: "ADMIN_PASSWORD_CHANGED",
        newValue
    }
}

export function adminSignOut() {
    return (dispatch, getState) => {
        dispatch(adminSignInPending());
        Auth.signOut()
            .then(() => dispatch(adminSignedOut()))
            .catch(() => dispatch(adminSignedOut()));
    }
}

export function checkAdminSignInState() {
    return (dispatch, getState) => {
        dispatch(adminSignInPending());
        Auth.currentSession()
            .then((session) => {
                if (session.isValid()) {
                    dispatch(adminSignedIn())
                } else {
                    dispatch(adminSignedOut())
                }
            })
            .catch((err) => {dispatch(adminSignedOut())});

    }
}

export function adminSignIn(username, password, successUrl) {
    return (dispatch, getState) => {
        dispatch(adminSignInPending());

        Auth.signIn(username, password)
            .then(() => {
                dispatch(adminSignedIn());
            })
            .catch((e) => {
                dispatch(adminSignedOut());
            })
    }
}

