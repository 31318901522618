import { API } from "aws-amplify";
import { getZingUser } from "./zingUser";

export const ZING_CONTACT_SUCCESS = "ZING_CONTACT_SUCCESS";
export const ZING_CONTACT_PENDING = "ZING_CONTACT_PENDING";
export const ZING_CONTACT_ERROR = "ZING_CONTACT_ERROR";

export function zingContactSuccess(zingContact) {
    return {
        type: "ZING_CONTACT_SUCCESS",
        zingUserUuid: zingContact.zingUserUuid,
        lastUpdated: zingContact.lastUpdated,
        displayName: zingContact.displayName
    }
}

export function zingContactPending(contactKey) {
    return {
        type: "ZING_CONTACT_PENDING",
        contactKey
    }
}

export function zingContactError(errorMessage) {
    return {
        type: "ZING_CONTACT_ERROR",
        errorMessage
    }
}

export function getZingContact(contactKey) {
    return (dispatch, getState) => {
        dispatch(zingContactPending(contactKey));

        API.get("user", `/zing-contact/${contactKey}`)
            .then((response) => {
                dispatch(getZingUser(response.zingUserUuid));
                return dispatch(zingContactSuccess(response));
            })
            .catch((e) => {
                dispatch(zingContactError(e.message));
            });

    }

}

