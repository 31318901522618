import { combineReducers } from "redux";
import administratorSession from "./administratorSession";
import friendRequest from "./friendRequest";
import userSession from "./userSession";
import zingCalendar from "./zingCalendar";
import zingContact from "./zingContact";
import zingUser from "./zingUser";

const rootReducer = combineReducers({
    administratorSession,
    friendRequest,
    userSession,
    zingCalendar,
    zingContact,
    zingUser
});

export default rootReducer;

