import React, { Fragment, Component } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { LinkContainer } from "react-router-bootstrap";
import Container from "react-bootstrap/Container";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { adminSignOut } from "../../actions/administratorSession"

// --------------- Component ----------------
class AdminNav extends Component {
    static propTypes = {
        signInState: PropTypes.string.isRequired,
        signOut: PropTypes.func.isRequired
    }

    render() {
        return (<div className="AdminNav">
            {/*-- Navigation */}
            <Navbar expand="lg" variant="light" id="mainNav" className="navbar-shrink">
                <Container>
                    <LinkContainer to="/">
                        <Navbar.Brand>Zing Diary</Navbar.Brand>
                    </LinkContainer>
                    <Navbar.Toggle />
                    <Navbar.Collapse>
                        <Nav className="ml-auto">
                            {this.props.signInState === "Pending" ? <LinkContainer to="/login">
                                <Nav.Link>[{this.props.signInState}]</Nav.Link>
                            </LinkContainer> : ""}
                            {this.props.signInState === "SignedIn" ? <Nav.Item onClick={this.props.signOut}>Logout</Nav.Item> : ""}
                            {this.props.signInState === "SignedOut" ? <Fragment>
                                <LinkContainer to="/login">
                                    <Nav.Link>Login</Nav.Link>
                                </LinkContainer>
                            </Fragment> : ""}
                        </Nav>
                    </Navbar.Collapse>
                </Container>

            </Navbar>
        </div>)
    }
}



// ----------- Container ------------
const mapStateToProps = (state) => {
    return {
        signInState: state.administratorSession.adminSignInState
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => { dispatch(adminSignOut()) },
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AdminNav);

