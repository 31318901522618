import React, { Component } from "react";
import { Form, FormGroup, FormControl } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import "./Login.css";
import { adminSignIn, onAdminPhoneChange, onAdminPasswordChange} from "../actions/administratorSession";
import { PropTypes } from "prop-types";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

class Login extends Component {
  static propTypes = {
    adminSignInState: PropTypes.string.isRequired,
    adminPhone: PropTypes.string.isRequired,
    adminPassword: PropTypes.string.isRequired,
    adminSignIn: PropTypes.func.isRequired,
    onAdminPhoneChange: PropTypes.func.isRequired,
    onAdminPasswordChange: PropTypes.func.isRequired,
  };

  validateForm() {
    return this.props.adminPhone.length > 0 && this.props.adminPassword.length > 0;
  }

  handlePhoneChange = event => {
    this.props.onAdminPhoneChange(event.target.value);
  }

  handlePasswordChange = event => {
    this.props.onAdminPasswordChange(event.target.value);
  }

  handleSubmit = async event => {
    event.preventDefault();
    this.props.adminSignIn(this.props.adminPhone, this.props.adminPassword);
  }

  render() {
    if (this.props.adminSignInState === "SignedIn") {
      return ( <Redirect to="/admin" push /> );
    } 

    return ( <div className="Login">
        <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="phone" size="lg">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              autoFocus
              type="tel"
              value={this.props.adminPhone}
              onChange={this.handlePhoneChange}
            />
          </FormGroup>
          <FormGroup controlId="password" size="lg">
            <Form.Label>Password</Form.Label>
            <FormControl
              value={this.props.adminassword}
              onChange={this.handlePasswordChange}
              type="password"
            />
          </FormGroup>
          <LoaderButton
            block
            size="lg"
            disabled={!(this.validateForm() && this.props.adminSignInState === "SignedOut")}
            type="submit"
            isLoading={this.props.adminSignInState === "Pending"}
            text="Login"
            loadingText="Logging in…"
            />
        </form>
      </div>
    );
  }
}

// ----------- Redux ------------
const mapStateToProps = (state) => {
  return {
      adminSignInState: state.administratorSession.adminSignInState,
      adminPhone: state.administratorSession.adminPhone,
      adminPassword: state.administratorSession.adminPassword,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
      adminSignIn: (username, password) => { dispatch(adminSignIn(username,password)) },
      onAdminPhoneChange: (newValue) => { dispatch(onAdminPhoneChange(newValue)) },
      onAdminPasswordChange: (newValue) => { dispatch(onAdminPasswordChange(newValue)) },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
