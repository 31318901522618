import { API } from "aws-amplify";

export const FRIEND_REQUEST_BY_SENDER_SUCCESS = "FRIEND_REQUEST_BY_SENDER_SUCCESS";
export const FRIEND_REQUEST_BY_SENDER_PENDING = "FRIEND_REQUEST_BY_SENDER_PENDING";
export const FRIEND_REQUEST_BY_SENDER_ERROR = "FRIEND_REQUEST_BY_SENDER_ERROR";

export function friendRequestBySenderSuccess(friendRequest) {
    return {
        type: FRIEND_REQUEST_BY_SENDER_SUCCESS,
        friendRequestUuid: friendRequest.friendRequestUuid,
        displayName: friendRequest.displayName,
        visibility: friendRequest.visibility,
        createdByUserUuid: friendRequest.createdByUserUuid,
        lastUpdated: friendRequest.lastUpdated,
    }
}

export function friendRequestBySenderPending(friendRequestUuid) {
    return {
        type: FRIEND_REQUEST_BY_SENDER_PENDING,
        friendRequestUuid
    }
}

export function friendRequestBySenderError(errorMessage) {
    return {
        type: FRIEND_REQUEST_BY_SENDER_ERROR,
        errorMessage
    }
}

export function getFriendRequestsForUser(zingUserUuid) {
    return (dispatch, getState) => {
        dispatch(friendRequestBySenderPending(zingUserUuid));

        let myInit = { // OPTIONAL
            queryStringParameters: {  // OPTIONAL
                senderUserUuid: zingUserUuid
            }
        }
        API.get("message", "/friend-request", myInit )
            .then((response) => {
                dispatch(friendRequestBySenderSuccess(zingUserUuid));
            })
            .catch((e) => {
                dispatch(friendRequestBySenderError(e.message));
            });

    }

}

